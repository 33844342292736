$transition: .2s cubic-bezier(0.4, 0, 0.2, 1);

.ilp-dropdown-button {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ilp-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  transform: translateX(-50%) scale(var(--_scale, 1));
  left: 0;
  width: fit-content;
  z-index: 3;
  transition: transform $transition, opacity $transition;
  transform-origin: top;

  &:not(.show) {
    --_scale: .75;
    opacity: 0;
    pointer-events: none;
  }
}