.cardcartelera {
  border: 1px solid $primary;
  border-left: 8px solid $primary;
  border-radius: 8px;
  position: relative;
  min-height: 114px;
  color: $text;
  height: 100%;

  &:hover {
    color: $primary;
  }

  .cardcartelera-arrow {
    position: absolute;
    bottom: 10px;
    right: 30px;
  }

  .hidden {
    display: none;
  }

  p {
    font-weight: 700;
  }
}
