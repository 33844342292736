.alert-color-success {
  border: 2px solid $success;
}

.alert-color-warning {
  border: 2px solid $warning;
}

.alert-color-error {
  border: 2px solid $error;
}

.alert-color-info {
  border: 2px solid $primary;
}

@media only screen and (max-width: 600px) {
  .alert {
    background-color: $background;
    position: fixed;
    top: 10vh;
    width: 96%;
    margin: 0.2rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(12, 14, 23, 0.75);
    border-radius: 8px;
    z-index: 4;
  }
}

@media only screen and (min-width: 600px) {
  .alert {
    background-color: $background;
    top: 11vh;
    right: 5rem;
    width: 400px;
    box-sizing: border-box;
    position: fixed;

    box-shadow: 0px 0px 8px rgba(12, 14, 23, 0.75);
    border-radius: 8px;
    z-index: 4;
  }
}