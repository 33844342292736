@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

h2 {
  font-weight: 600;
  color: $white;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a,
a:hover {
  text-decoration: none;
  color: $white;
}

button {
  font-size: 100%;
  font-family: inherit;
  background-color: $background;
}

body {
  background-color: $background;
}

main {
  width: 100%;
}

.main-div {
  padding-top: 4.5rem;
  width: 100%;
}

p {
  margin: 0;
}

nav {
  z-index: 2;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // border: 1px solid green;
  -webkit-box-shadow: 0 0 0px 1000px $background inset;
  transition: background-color 5000s ease-in-out 0s;
}

.w-125 {
  width: 125%;
}

.w-100 {
  width: 100%;
}

.w-95 {
  width: 95%;
}

.w-92 {
  width: 92%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.h-125 {
  height: 125%;
}

.h-100 {
  height: 100%;
}

.h-95 {
  height: 95%;
}

.h-80 {
  height: 80%;
}

.h-70 {
  height: 70%;
}

.h-60 {
  height: 60%;
}

.h-40 {
  height: 40%;
}

.h-20 {
  height: 20%;
}

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.vh-80 {
  height: 80vh;
}

.vh-70 {
  height: 70vh;
}

.vh-60 {
  height: 60vh;
}

.vh-50 {
  height: 50vh;
}

.vh-40 {
  height: 40vh;
}

.vh-30 {
  height: 30vh;
}

.vh-20 {
  height: 20vh;
}

.vh-10 {
  height: 10vh;
}

// Classes for flex
.center-box {
  align-items: center;
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.space-between {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

///////////////////////////////////////////
// Classes for font (colours,positioning)
.color-primary {
  color: $primary;

  &:hover {
    color: $primary;
  }
}

.color-header-cartelera {
  color: $header-cartelera;

  &:hover {
    color: $header-cartelera;
  }
}

.color-inputs {
  color: $input;
}

.color-mail-cco {
  color: $mail-cco;
}

.color-text {
  color: $text;

  svg {
    color: $text;
  }
}

.color-white {
  color: $white;
}

.color-white-svg {
  color: $white;

  svg {
    color: $white;
  }
}

.color-secondary {
  color: $secondary;
}

.color-hover-1 {
  color: $hover-1;
}

.color-black {
  color: $black;
}

.color-disabled {
  color: $disabled;

  svg {
    color: $disabled;
  }
}

.color-success {
  color: $success;
}

.color-labels {
  color: $labels;

  svg {
    color: $labels;
  }

  path {
    color: $labels;
  }
}

.color-error {
  color: $error;
}

.color-warning {
  color: $warning;
}

.color-header-cartelera {
  color: $header-cartelera;
}

.text-nowrap {
  white-space: nowrap;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

// Classes for rowMobile

.row__mobile {
  width: 100vw;
  overflow-x: auto;
  white-space: "nowrap";
}

.row__mobile::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.row__mobile {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

//borders
.border-none {
  border: none;
}

.border-bottom {
  border-bottom: $white 1px solid;
}

.border-bottom-none {
  border-bottom: none!important;
}

///////// positions

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-absolute-left {
  position: absolute;
  left: 0;
}

.position-absolute-bottom {
  position: absolute;
  bottom: 0;
}

.position-sticky-bottom {
  position: sticky;
  bottom: 0;
}

.position-sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}

.position-absolute-top {
  position: absolute;
  top: 0;
}

.position-absolute-right {
  position: absolute;
  right: 0;
}

.position-fixed-bottom {
  position: fixed;
  bottom: 0;
}

.position-fixed {
  position: fixed;
}

.object-fit-cover {
  object-fit: cover;
}

.text-transform-none {
  text-transform: none;
}

.hidden {
  display: none;
}

.cursor-pointer{
  cursor: pointer;
}