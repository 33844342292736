.budget__container {
    .success {
        color: $success;
    }

    .error {
        color: $error;
    }
}

.budgetnew__container {
    .budgetnew__form {
        border-radius: 8px;
        border: 1px solid $secondary;

        .newbudget-form-select {
            border: none;
            border-bottom: 3px solid $input;

            &:focus {
                border-bottom: 3px solid $primary;
            }
        }

        .newbudget__total {
            background-color: $hover-4;
        }
    }
}

.button_z-indez_top {
    z-index: 1000;
}