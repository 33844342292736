.emptystate__container {
    img {
        width: 200px;
        height: 200px;

    }

    h3 {
        text-align: center;
    }

    p {
        text-align: center;
    }
}