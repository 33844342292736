.btn {
    min-width: 106px;
    height: 48px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.button-primary {
    color: $white;
    padding: 8px 32px;
    background-color: $primary;
    border-radius: 8px;
    outline: none;
    border: 0;
    border-color: none;
    min-height: 48px;

    &:hover {
        background-color: $hover-1;
        color: $white;
    }

    &:disabled {
        background-color: $disabled;
    }
}

.button-secondary {
    border: 2px solid $primary;
    background-color: inherit;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none;
    padding: 8px 16px;
    color: $primary;

    svg {
        color: $primary;
    }

    &:hover {
        border: 2px solid $hover-1;
        color: $hover-1;
        background-color: $white;
        svg {
            color: $hover-1;

        }
    }

    &:disabled {
        border: 2px solid $disabled;
        color: $disabled;
    }
}

.button-default {
    border: 2px solid $primary;
    background-color: inherit;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    color: $primary;

    svg {
        color: $primary;
    }

    &:hover {
        border: 2px solid $hover-1;
        color: $hover-1;

        svg {
            color: $hover-1;

        }
    }

    &:disabled {
        border: 2px solid $disabled;
        color: $disabled;
    }
}

.button-terciary {
    border: 2px solid transparent;
    background-color: inherit;
    box-sizing: border-box;
    color: $primary;

    svg {
        color: $primary;
    }

    &:hover {
        color: $hover-1;

        svg {
            color: $text;
        }
    }
}
.button-quaternary {
    border: 2px solid transparent;
    background-color: inherit;
    box-sizing: border-box;
    color: $primary;
    padding: 8px 32px;

    svg {
        color: $primary;
    }

    &:hover {
        color: $hover-1;

        svg {
            color: $hover-1;
        }
    }
}

.button__only-icon-hover {
    border: 2px solid transparent;
    background-color: inherit;
    box-sizing: border-box;
    border-radius: 20px;
    outline: none;
    color: $text;

    svg {
        color: $primary;
    }

    &:hover {
        background-color: $hover-4;
        color: $text;

        svg {
            color: $hover-4;
        }
    }

    &:disabled {
        border: 2px solid $disabled;
        color: $disabled;
    }
}



.button__only-icon-withoutcolor {
    border: none;
    background-color: inherit;
    outline: 0;
}

.button__arrowAnimation-active {
    .icon-selected {
        transition: 0.3s;
        transform: rotate(180deg);
    }

    .icon-selected-1 {
        transition: 0.3s;
        transform: rotate(90deg);
    }
}

.button__arrowAnimation-disable {
    .icon-selected {
        transition: 0.3s;
        transform: rotate(0);
    }

    .icon-selected-1 {
        transition: 0.3s;
        transform: rotate(0);
    }
}

.circular-border-primary {
    border: 3px solid $primary;
    border-radius: 50%;
}

.circular-border-success {
    border: 3px solid $success;
    border-radius: 50%;
    svg {
        color: $success;
        margin: 0!important;
        width: 16px;
        height: 24px;
    }
}
.circular-border-qualify {
    border: 1px solid $success;
    border-radius: 100%;
    background-color: $success;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    svg {
        color: $white;
        margin: 0!important;
    }
}

.circular-border-error {
    border: 3px solid $error;
    border-radius: 50%;
    svg {
        color: $error;
        margin: 0!important;
        width: 16px;
        height: 24px;
    }
}

.circular-border-disabled {
    border: 3px solid $disabled;
    border-radius: 50%;
}
.border-disabled {
    border: none;
    border-radius: none;
    margin-left: 10px;
    margin-bottom: 8px;
}