.messages__container {
    hr {
        border: 0;
        border-bottom: 1px solid $secondary;
        display: block;
        height: 1px;
        margin-top: -3px;
    }
}

.newmessages__container {
    .newmessages__mail {
        border: 1px solid $secondary;
        border-radius: 8px;
    }

    .newmessages__input {
        border: none;
        border-bottom: 3px solid $input;
    }

    .messageform__mails-container {
        margin-top: auto;

        .messageform__mails-format {
            max-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            border: 1px solid $secondary;
            box-sizing: border-box;
            border-radius: 20px;
        }
    }

    .newmessages__textarea-attach {
        position: absolute;
        bottom: 10px;
        left: 5px;

        input {
            display: none;
        }
    }

    .newmessages__textarea {
        border: 2px solid $input;
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.messagedetail__container {
    border: 1px solid $secondary;
    border-radius: 8px;
    min-height: 600px;

    .messagedetail__mail-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        .messagedetail__profile-img {
            color: $disabled;
            position: absolute;
            width: 40px;
            height: 40px;
            top: 5px;
            left: -50px;
            border-radius: 100%;
        }

        .messagedetail__more-information {
            max-height: 350px;
            overflow-y: scroll;
            position: absolute;
            background-color: $white;
            border: 1px solid $secondary;
            box-sizing: border-box;
            border-radius: 8px;
            z-index: 4;
            top: 30px;
            left: 40%;
        }
    }

    .messagedetail__mail-body {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        .messagesdetail__html {
            border: none;
            width: 100%;
            overflow: visible;
        }
    }
}