.icons__8 {
  height: 20px;
  width: 8px;

  svg {
    height: 8px;
    width: 8px;
  }
}

.icons__12 {
  height: 20px;
  width: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
}

.icons__16 {
  height: 16px;
  width: 16px;

  svg {
    height: 16px;
    width: 16px;
  }

  @media screen and (max-width: 460px) {
    height: auto;

    svg {
      height: auto;
    }
  }
}

.icons__18 {
  height: 18px;
  width: 18px;

  svg {
    height: 18px;
    width: 18px;
  }
}

.icons__20 {
  height: 20px;
  width: 20px;

  svg {
    height: 20px;
    width: 20px;
  }
}

.icons__22 {
  height: 22px;
  width: 22px;

  svg {
    height: 22px;
    width: 22px;
  }
}

.icons__24 {
  height: 24px;
  width: 24px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.icons__36 {
  height: 36px;
  width: 36px;

  svg {
    height: 36px;
    width: 36px;
  }
}

.icons__40 {
  height: 40px;
  width: 40px;

  svg {
    height: 40px;
    width: 40px;
  }
}

.icons__48 {
  height: 48px;
  width: 48px;

  svg {
    height: 48px;
    width: 48px;
  }
}