.subjectDetail__container {
    margin-bottom: 6rem;
}

.subjectCriteria__container {
    & > button {
        right: 0;
        z-index: 1;
    }
    .subjectCriteria__input {
        border: 2px $input solid;
        border-radius: 4px;
        textarea {
            border: none;
            resize: none;

            &:focus-visible {
                outline: none;
            }
        }
        &:hover textarea {
            resize: vertical;
        }
    }
}
