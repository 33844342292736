.sidenav {
    width: 522px;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0px;
    right: 0;
    background-color: $white;
    overflow-x: hidden;
    padding-top: 60px;
}

.back-opacity {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
}