.userpersonaldata__form-input {
    label {
        position: absolute;
        bottom: 26px;
    }

    input {
        border: none;
        border-bottom: 3px solid $input;

        &:focus {
            border-bottom: 3px solid $primary;
        }
    }
}