.customoptions__active-buttons {
    border-bottom: 3px solid $primary;
    border-radius: 1px;

    button {
        color: $primary;
    }
}

.customoptions__hr {
    border: 0;
    border-bottom: 1px solid $secondary;
    display: block;
    height: 1px;
    margin-top: -3px;
}