.newuser__container {
    .newuser__form {
        border-radius: 8px;
        border: 1px solid $secondary;

        .newUserForm__container {
            .not-teacher-options {
                .p-radiobutton-focused {
                    .p-radiobutton-box {
                        border: 3px solid $primary;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}