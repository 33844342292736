.remindereventcard__container {
    min-height: 254px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $text;
    border-radius: 8px;
    border: 2px solid $primary;

    .remindereventcard__hr {
        border: 0;
        border-bottom: 1px solid $secondary;
        display: block;
        height: 1px;
    }

    .remindereventcard__link {
        color: $primary;

        &:hover {
            color: $text;
        }
    }
}