.newevent__form {
    border: 1px solid $secondary;
    border-radius: 8px;

    .newevent-form-select {
        border: none;
        border-bottom: 2px solid $input;

        &:focus {
            border-bottom: 2px solid $primary;
        }
    }

    hr {
        border: 0;
        border-bottom: 1px solid $secondary;
        display: block;
        height: 1px;
        margin-top: -3px;
    }


    .messageform__mails-container {
        margin-top: auto;

        .messageform__mails-format {
            max-height: 30px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            border: 1px solid $secondary;
            box-sizing: border-box;
            border-radius: 20px;
        }
    }

    .newmessages__input {
        border: none;
        border-bottom: 3px solid $input;
    }

    .newevent__textarea {
        border: 3px solid $input;
        box-sizing: border-box;
        border-radius: 4px;

        &:focus {
            border: 3px solid $primary;
            outline: none;
        }
    }

}

.eventdetail__container {
    border: 1px solid $secondary;
    border-radius: 8px;

    hr {
        border: 0;
        border-bottom: 1px solid $secondary;
        display: block;
        height: 1px;
        margin-top: -3px;
    }

}