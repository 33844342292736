.drive-file {
  $left-detail-width: 8px;
  $transition: .2s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  padding-left: 8px + $left-detail-width;
  border-radius: 8px;
  --accent: #{$secondary};
  border: 1px solid var(--accent);
  margin-bottom: 0.5rem;
  cursor: pointer;
  // overflow: hidden;

  &,
  &:hover,
  &:focus {
    // outline: none;
    color: $text;
  }

  &,
  &::before {
    transition: background-color $transition, transform $transition, opacity $transition;
  }

  &::before {
    content: '';
    display: flex;
    width: $left-detail-width;
    height: 100%;
    background-color: var(--accent);
    position: absolute;
    left: 0;
    border-radius: 8px 0 0 8px;
  }

  &:hover {
    --accent: #{$primary};
  }

  &.active {
    transform: scale(0.97);
    opacity: 0.8;
  }
}