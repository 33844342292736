.accordion {
    .accordion__card {
        border: $secondary 1px solid;
        border-radius: 1rem;
        .accordion__header {
            background-color: transparent;
            border: none;
            .accordion__button {
                border: none;
                transition: transform 0.2s ease-in-out;
                &.accordion__button--open {
                    transform: rotate(-180deg);
                }
            }
        }
        .accordion__body {
            padding: 0;
        }
    }
}
