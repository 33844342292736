//Colors
$primary: #0056A8;
$secondary: #B9D3EF;
$text: #0D0A14;
$header:#333333;
$header-cartelera:#393939;
$background:#FDFDFD;

//Colors states
$success: #0B7B16;
$warning: #F39E1E;
$error: #E73C3C;

//hover
$hover-1: #003364;
$hover-2: #81B6EF;
$hover-3: #FCFDFF;
$hover-4: #F1F8FF;

//colors details
$disabled: #585858;
$input: #757575;
$labels:#464646;
$read-message:#E5E5E5;
$mail-cco:#836969;
//Google
$white: #ffffff;
$black: #000;