.namesearcher__selector {
    position: absolute;
    border-radius: 0 0 8px 8px;
    border: 1px solid $primary;
    background-color: $background;
    z-index: 5;

    .active {
        background-color: $hover-4;
        color: $primary;
    }
}