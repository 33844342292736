.board-row-color-1 {
    --color-one: #{$primary};
}

.board-row-color-2 {
    --color-one: #{$primary};
}

.board-row-color-3 {
    --color-one: #{$hover-4};
}

//messages
.board__container {
    .board__first-row {
        border: 1px solid $secondary;
        border-bottom: 2px solid $secondary;
        border-radius: 8px 8px 0 0;
        background-color: var(--color-one);

        .messages-amount {
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .board__row {
        border: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
        color: $text;

        &:hover {
            background-color: $hover-4;
        }
    }

    .board__last-row {
        border: 1px solid $secondary;
        border-radius: 0 0 8px 8px;
    }
}

.scrollable-board__container {
    overflow: hidden;
    border-radius: 8px;
    .scroll-x-auto {
        overflow-x: auto;
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        & {
            scrollbar-width: auto;
            scrollbar-color: #8f54a0 #e1e2a6;
        }

        /* Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: #F1F8FF;
            border-radius:0px 0px 8px 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #757575;
            border-radius:0px 0px 8px 8px;
        }
    }
    .width-max-content {
        width: max-content;
    }

    .board__column {
        .board__first-left-row {
            background-color: var(--color-one);
            border-bottom: 2px solid $secondary;
            border-left: 1px solid $secondary;
            border-top: 1px solid $secondary;
            border-radius: 8px 0 0 0;
        }
        .board__first-right-row {
            background-color: var(--color-one);
            border-bottom: 2px solid $secondary;
            border-top: 1px solid $secondary;
            border-right: 1px solid $secondary;
            border-radius: 0 8px 0 0;
        }
        .board__left-row {
            .col{
                border-left: 1px solid $secondary;
            }
            &:last-child{
                border-radius: 0 0 0 8px;
                .col{
                    border-radius: 0 0 0 8px;
                }
            }
        }
        .board__right-row {
            .col{
                border-right: 1px solid $secondary;
            }
        }
        .scrollable-item {
            width: 60px;
            border-left: 1px solid $secondary;
        }
        .board__row {
            border-bottom: 1px solid $secondary;
            color: $text;

            &:hover {
                background-color: $hover-4;
            }
            .radio-button {
                margin-top: 10px;
                margin-bottom: 10px;
                cursor: default;
                .p-disabled{
                    opacity: 1;
                }
            }
        }
    }
}
