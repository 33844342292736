@import '../base/_settings.scss';

.switch__container {
    position: relative;
    width: fit-content;
    .switch__ball-container {
        position: absolute;
        right: 0;
        border: 1px solid $secondary;
        background-color: $secondary;
        padding: 1px;
        width: 36px;
        border-radius: 100px;
        .switch__ball {
            border-radius: 100%;
            background-color: $white;
            height: 16px;
            width: 16px;
            transition: transform .25s ease;
        }
    }
    .switch {
        z-index: 1;
        height: 24px;
        width: 40px;
        opacity: 0;
    }
    &:hover {
        .switch__ball-container {
            border-color: $hover-2;

            .switch__ball {
                background-color: $hover-2;
            }
        }
    }
    &.active {
        .switch__ball-container {
            background-color: $primary;
            border-color: $primary;

            .switch__ball {
                transform: translateX(100%);
                background-color: $white;
            }
        }
        &:hover {
            .switch__ball-container {
                background-color: $hover-2;
                border-color: $hover-2;
            }
        }
        &.disabled {
            .switch__ball-container {
                border-color: $disabled;
                background-color: $disabled;
                
                .switch__ball {
                    background-color: $white;
                }
            }
        }
    }
    &.disabled {
        .switch__ball-container {
            border-color: $disabled;
            background-color: $disabled;

            .switch__ball {
                background-color: $white;
            }
        }
    }
}