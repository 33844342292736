.upcomingEventCard {
    background-color: $primary;
    opacity: 0.8;
    max-height: 254px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $white;
    border-radius: 8px;

    .upcomingEventCard__hr {
        border: 0;
        border-bottom: 1px solid $white;
        display: block;
        height: 1px;
    }

    .upcomingEventCard__link:hover {
        color: $text;
    }
}