/*
   Fonts
   ========================================================================== */
$light: 300;
$bold: 500;
// ---
$desktopBase: 1rem;
$desktopScale: 1.15;
$mobileBase: 1rem;
$mobileScale: 1.15;

$desktopSize-10: $desktopBase / $desktopScale; //13
$desktopSize-9: $desktopBase; //16px
$desktopSize-8: $desktopSize-9 * $desktopScale; //18.4
$desktopSize-7: $desktopSize-8 * $desktopScale; //21.16px
$desktopSize-6: $desktopSize-7 * $desktopScale; //21.16px * 1.15 =24.334px
$desktopSize-5: $desktopSize-6 * $desktopScale; // 24.33416px *1.15 =28px
$desktopSize-4: $desktopSize-5 * $desktopScale; //28px*1.15 =32px
$desktopSize-3: $desktopSize-4 * $desktopScale; //32px*1.15 = 37px
$desktopSize-2: $desktopSize-3 * $desktopScale; //37*1.15= 43px
$desktopSize-1: $desktopSize-2 * $desktopScale; //18.4px = 49px

$mobileSize-10: $mobileBase / $mobileScale; //13
$mobileSize-9: $mobileBase; //16px
$mobileSize-8: $mobileSize-9 * $mobileScale; //18.4
$mobileSize-7: $mobileSize-8 * $mobileScale; //21.16px
$mobileSize-6: $mobileSize-7 * $mobileScale; //21.16px * 1.15 =24.334px
$mobileSize-5: $mobileSize-6 * $mobileScale; // 24.33416px *1.15 =28px
$mobileSize-4: $mobileSize-5 * $mobileScale; //28px*1.15 =32px
$mobileSize-3: $mobileSize-4 * $mobileScale; //32px*1.15 = 37px
$mobileSize-2: $mobileSize-3 * $mobileScale; //37*1.15= 43px
$mobileSize-1: $mobileSize-2 * $mobileScale; //18.4px = 49px
// ---

@mixin fontSize0() {
  font-size: 48px;

  @media only screen and (max-width: 450px) {
    font-size: 32px;
  }
}

@mixin fontSize1() {
  //para el h1
  font-size: $desktopSize-1; //49 pxieles en desktop

  //49
  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-1;
  }
}

@mixin fontSize2() {
  font-size: $desktopSize-2;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-2;
  }
}

@mixin fontSize3() {
  font-size: $desktopSize-3;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-3;
  }
}

@mixin fontSize4() {
  font-size: $desktopSize-4;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-4;
  }
}

@mixin fontSize5() {
  font-size: $desktopSize-5;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-5;
  }
}

@mixin fontSize6() {
  //para el body
  font-size: $desktopSize-6;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-6;
  }
}

@mixin fontSize7() {
  //para el body
  font-size: $desktopSize-7;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-7;
  }
}

@mixin fontSize8() {
  //para el body
  font-size: $desktopSize-8;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-8;
  }
}

@mixin fontSize9() {
  //para el body
  font-size: $desktopSize-9;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-9;
  }
}

@mixin fontSize10() {
  //para el body
  font-size: $desktopSize-10;

  @media only screen and (max-width: 450px) {
    font-size: $mobileSize-10;
  }
}
@mixin fontSize11() {
  line-height: 51px;
  font-size: 34px;

}

//! Exact fonts

h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.005em;

  @media only screen and (min-width: 450px) {
    font-size: 3.75rem; //60pix
    line-height: 90px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 3rem;
    // line-height: 58px;
  }
}

h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;

  @media only screen and (min-width: 450px) {
    font-size: 3rem; //48px
    line-height: 72px;

  }

  @media only screen and (max-width: 450px) {
    font-size: 2rem;
    // line-height: 40px;
  }
}

h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /* identical to box height, or 150% */
  letter-spacing: 0.0025em;

  @media only screen and (min-width: 450px) {
    line-height: 51px;
    font-size: 1.875rem;
    // line-height: 40px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.5rem;
    // line-height: 40px;
  }
}

h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;

  @media only screen and (min-width: 450px) {
    line-height: 36px;
    font-size: 1.5rem; //24px
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
}

h5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;

  @media only screen and (min-width: 450px) {
    font-size: 1.25rem; //20px
    line-height: 30px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.13rem;
    line-height: 22px;
  }
}


// only FontSize mixins
@mixin Large {
  @media only screen and (min-width: 450px) {
    font-size: 1.125rem; //18px
  }

  @media only screen and (max-width: 450px) {
    font-size: 1rem; //16px
  }
}

@mixin Medium {
  @media only screen and (min-width: 450px) {
    font-size: 1rem; //16px
  }

  @media only screen and (max-width: 450px) {
    font-size: 0.88rem;
  }
}

@mixin Small {
  @media only screen and (min-width: 450px) {
    font-size: 0.875rem; //14px
  }

  @media only screen and (max-width: 450px) {
    font-size: 0.75rem;
  }
}

//Subtitles:

.subtitle-l {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;

  @media only screen and (min-width: 450px) {
    line-height: 42px;
    font-size: 1.75rem; //28px
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.5rem;
  }
}

.subtitle-m {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;

  @media only screen and (min-width: 450px) {
    line-height: 36px;
    font-size: 1.5rem; //28px
  }

  @media only screen and (max-width: 450px) {
    font-size: 1.125rem;
  }
}

//Body types
.body-l {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  @include Large;
}

.body-l-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Large;
}

.body-l-sb-italic {
  font-family: Roboto;
  font-style: italic;
  font-weight: 600;
  /* identical to box height, or 156% */

  text-align: center;
  /* identical to box height, or 125% */
  @include Large;
}

.body-l-b {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  /* identical to box height, or 125% */
  @include Large;
}




.body-m {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  /* identical to box height, or 125% */

  @include Medium;
}

.body-m-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Medium;
}

.body-m-sb-italic {
  font-family: Roboto;
  font-style: italic;
  font-weight: 600;
  @include Medium;
}

.body-m-b {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Medium;
}

.body-s {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  @include Small;
}

.body-s-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Small;
}

.body-s-sb-italic {
  font-family: Roboto;
  font-style: italic;
  font-weight: 600;
  /* identical to box height, or 15% */
  @include Small;
}

.body-s-b {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  @include Small;
}

.button-l-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Large;
}

.button-m-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  /* identical to box height, or 133% */
  @include Medium;
}

.button-s-sb {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Small;
}

.input-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  @include Small;
}

.input-ref {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  @include Small;
}
.aside-canvas {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  @include fontSize11;
}