.custom-input {
    .label-up {
        position: absolute;
        bottom: 24px;
    }

    .label-down {
        position: absolute;
        left: 0;
        bottom: -35px;
    }

    input {
        border: none;
        border-bottom: 3px solid $input;
        color: $labels;
        padding-bottom: 2px;

        &:focus {
            border-bottom: 3px solid $primary;
            outline: none;

            &::placeholder {
                color: transparent;
            }
        }

        &:disabled {
            // border-bottom: 3px solid $primary;
            background-color: transparent;
        }
    }
}

.tam__custom {
    width: 30px;
    font-weight: bold;
    text-align: start;
}
