.navbar__container {
    align-items: center;
    background-color: $header;
    display: flex;
    flex-direction: row;
    height: 4.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4;

    .header__container {
        align-items: center;
        background-color: $header;
        color: $white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .navbar_profile {
            display: flex;
            align-items: center;

            .show-box {
                background-color: $white;
                border-radius: 0px 0px 8px 8px;
                box-shadow: 0px 0px 4px rgba(19, 15, 29, 0.25);
                border-radius: 0px 0px 8px 8px;
                min-width: 212px;
                position: absolute;
                right: 0;
                top: 57px;

                hr {
                    border: 0;
                    border-bottom: 1px solid $secondary;
                    display: block;
                    height: 1px;
                    width: 100%;
                    margin-top: -3px;
                }

                .show-box-item {
                    display: flex;
                    justify-content: flex-start;
                    @include Small;

                    &:hover {
                        background-color: $hover-4;
                        color: $primary;
                        font-weight: bold;
                    }
                }

                .showicon {
                    color: $disabled;

                    i {
                        opacity: 0;
                    }

                    &:hover {
                        background-color: $hover-4;
                        color: $primary;

                        i {
                            opacity: 1;
                            color: $primary;
                        }
                    }
                }
            }

            img {
                border-radius: 100%;
                height: 40px;
                object-fit: cover;
                width: 40px;
            }
        }
    }
}

.sidebar-width-open {
    --width-sidebar: #{220px};
}

.sidebar-width-close {
    --width-sidebar: #{72px};
}

.aside__width {
    width: var(--width-sidebar);
    transition: width 0.2s ease-out;
    height: calc(100vh - 4.5rem);
    position: sticky;
}

.sidebar__container {
    height: 100%;
    width: var(--width-sidebar);
}

.navbar__burger-button {
    align-items: center;
    background-color: $header-cartelera;
    display: flex;
    max-height: 72px;
    min-width: var(--width-sidebar);
    transition: width 0.2s ease-out;

    svg {
        color: $white;
    }

    path {
        color: $white;
    }
}

.sidebar {
    width: var(--width-sidebar);
    background-color: $white;
    box-shadow: 1px 0px 4px rgba(13, 10, 20, 0.1);
    height: 100%;
    transition: width 0.2s ease-out;
    position: sticky;
    top: 4.5rem;

    .navbar__link-div {
        color: $text;
        text-decoration: none;

        & > div {
            height: 21px;
        }

        &:hover {
            background-color: $hover-4;
            color: $primary;

            svg {
                color: $primary;
            }
        }
    }

    .link-active {
        background-color: $hover-4;
        color: $primary;

        svg {
            color: $primary;
        }
    }
}

.hr-open {
    width: 85%;
    border: 0;
    border-bottom: 2px solid $secondary;
    display: block;
    height: 1px;
}

.hr-closed {
    width: 55%;
    background-color: inherit;
    color: inherit;
    border-bottom: 2px solid $secondary;
}
