.coursenew_form {
    border-radius: 8px;
    border: 1px solid $secondary;
}

.time-selector {
    width: 110%;
}

.new-assign-card-div img {
    width: 40px;
    height: 40px;
}

.new-assign-card-div .p-normal {
    font-weight: 400;
}